define(['app'], function(app) {

  var productReleaseDate = function() {
    var component = this;

    var _config = {
      selectors: {
        component: '[data-component=productReleaseDate]',
        releaseDate: '[data-product-release-date]'
      },
      subscribeChannels: {
        releaseDate: 'productReleaseDate/newReleaseDate'
      }
    };

    var _init = function(element) {
      component.element = element;

      var releaseDateSubscribe = app.subscribe(component.config.subscribeChannels.releaseDate,
        component.getNewReleaseDate);
      component.checkSubscribeMessages(releaseDateSubscribe, component.getNewReleaseDate);

      return component;
    };

    var _releaseDateReceived = function(releaseDateData) {
      var newContainer = document.createElement('div');
      newContainer.innerHTML = releaseDateData;
      var newElem = newContainer.querySelector(component.config.selectors.component);
      var newReleaseDate = newElem.querySelector(component.config.selectors.releaseDate);
      var oldReleaseDate = component.element.querySelector(component.config.selectors.releaseDate);

      if (newReleaseDate) {
        component.element.removeChild(oldReleaseDate);
        component.element.appendChild(newReleaseDate);
      }
    };

    var _getNewReleaseDate = function(productId) {
      app.ajax.get({
        url: '/' + productId + '.releaseDate',
        success: component.releaseDateReceived,
        error: component.releaseDateErrorHandler
      });
    };

    var _releaseDateErrorHandler = function() {
      console.error(
        'ERROR: Could not retrieve child product release date');
    };

    var _checkSubscribeMessages = function(subscription, callback) {
      if (subscription.messages.length > 0) {
        var lastItem = subscription.messages.slice(-1)[0];
        callback(lastItem);
      }
    };

    component.init = _init;
    component.config = _config;
    component.releaseDateReceived = _releaseDateReceived;
    component.getNewReleaseDate = _getNewReleaseDate;
    component.releaseDateErrorHandler = _releaseDateErrorHandler;
    component.checkSubscribeMessages = _checkSubscribeMessages;

    return component;
  };

  return productReleaseDate;
});
